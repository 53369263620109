document.addEventListener('DOMContentLoaded', function() {

	jQuery('.MainNav a[href*=#]').on('click', function(e) {
		e.preventDefault();
		var target = this.getAttribute('href');
		if (target) {
			jQuery('html, body').animate({scrollTop: jQuery(target).offset().top - 88});
			//history.pushState(null, document.title, target);
		}
	});

	// First click opens the menu on touch screens
	if ( 'ontouchstart' in window ) {
		var touched = false;
		jQuery('body')
			.on('touchstart click', '.MainNav-list > .menu-item-has-children > a', function(e) {
				e.preventDefault();
				if ('click' !== e.type) {
					var el = jQuery(this).parent();
					if (!el.hasClass('focus')) {
						el.toggleClass('focus');
						el.siblings('.focus').removeClass('focus');
					} else {
						window.location = this.href;
					}
				}
			});
	} else {
		jQuery('.MainNav').find('a').on('focus blur', function() {
			var el = jQuery(this).closest('.menu-item-has-children');
			el.toggleClass('focus');
		});
	}
/*
	jQuery('.MainNav').find('a').on('focus blur', function() {
		jQuery(this).parents('.menu-item').toggleClass('focus');
	});
*/
/*
	if ( 'ontouchstart' in window ) {
		jQuery('body').on('touchstart', '.menu-item-has-children > a', function(e) {
			var el = jQuery(this).parent();
			if (!el.hasClass('focus')) {
				e.preventDefault();
				el.toggleClass('focus');
				el.siblings('.focus').removeClass('focus');
			}
		});
	}
*/

});
