document.addEventListener('DOMContentLoaded', function() {

	jQuery(document).on('click', '.shareLinks a', function(e) {
		if (!this.classList.contains('share-email')) {
			e.preventDefault();
			window.open(this.href, 'Share', 'height=480, width=560, top=' + (jQuery(window).height() / 2 - 230) + ', left=' + (jQuery(window).width() / 2) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
		}
	});

});
