var mobileNav,
	container,
	navOverlay = document.createElement('div'),
	i,l;

function nav_toggleDrawer(e) {
	e.preventDefault();
	mobileNav.classList.toggle('is-active');
	document.body.classList.toggle('is-navOpen');
	this.blur();
}

document.addEventListener('DOMContentLoaded', function() {
	mobileNav = document.getElementById('MobileNav');
	container = document.getElementById('Page');

	// Click to open
	if (mobileNav) {
		mobileNav.addEventListener('click', nav_toggleDrawer);
	}

	// Add overlay
	if (container && navOverlay) {
		navOverlay.classList.add('NavOverlay');
		navOverlay.addEventListener('click', nav_toggleDrawer);
		container.appendChild(navOverlay);
	}

	// DS API cart connect
	jQuery.ajax({
		type: "GET",
		url: "https://" + publicViewHost + "/restricted/common/sessionInfo.jsp",
		dataType: "jsonp",
		success: function(json) {
			var cartCount = document.querySelector('.cart-count'),
				loginLink = jQuery('#menu-item-274 > a');
			
			// Update the cart
			if (cartCount) {
				if (json.numCartItems) {
					cartCount.innerHTML = json.numCartItems;
				} else {
					cartCount.innerHTML = '0';
				}
			}

			// Update the Log In URL
			if (loginLink) {
				if ('no' !== json.isStudentLogon && json.studentLogoutUrl) {
					loginLink.href = json.studentLogoutUrl;
					loginLink.innerHTML = 'Log Out';
				} else if (json.studentLogonUrl) {
					loginLink.href = json.studentLogonUrl;
					loginLink.innerHTML = 'Log In';
				}
			}
		},
		error:function(){
			console.log('ERROR: Could not get cart');
		},
	});

});
